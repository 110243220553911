import { Component, Output, EventEmitter, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../../appconstants';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.css'
})
export class NavBarComponent {
  protected basePath = environment.umbracoBaseUri + "/";
  @Output() loginClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() CommonContent :any;
  userRole = '';
  jobSeeker = AppConstants.JOBSEEKER;
  employer = AppConstants.EMPLOYER;
  lawaStaff = AppConstants.LAWASTAFF;
  commPartner = AppConstants.COMMPARTNER;

  constructor() {
  }

  ngOnInit(): void {
    const user = localStorage.getItem(AppConstants.USER);
    if (user) {
      this.userRole = JSON.parse(user).role;
    }
  }


  onLoginClicked(): void {
    this.loginClicked.emit(); // Emit the event when the button is clicked
  }
}

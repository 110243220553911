import { Component, Inject, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  PopupRequest,
  RedirectRequest,
  SsoSilentRequest,
  PublicClientApplication,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { b2cPolicies, SignUpRoles } from '@portal/configs';
import { DatePipe } from '@angular/common';
import { ContentService } from '@portal/services';
import { AuthService } from './services/authguard/auth.service';
import { UserInfoService } from './services/userclaims/userinfo.service';
import { AppConstants } from './appconstants';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from './services/profile.service';
const b2cConfigs = environment.b2cConfigs;

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  providers: [DatePipe],
})
export class AppComponent implements OnInit {
  commonContent: any;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private toastr: ToastrService,
    private profileService: ProfileService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private readonly contentService: ContentService,
    private readonly auth: AuthService,
    private userInfoService: UserInfoService,
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {}
  authenticated: boolean = false;
  ngOnInit(): void {
    this.subscribeEvents();
    this.getCommonContent();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;
        this.authService.instance.setActiveAccount(payload.account);

        if (!localStorage.getItem('Access-Token-Azure-B2C')) {
          const user = {
            userId: '',
            firstName: '',
            lastName: '',
            email: payload?.account?.username,
            role: this.getRole(idtoken),
          };
          localStorage.setItem(AppConstants.USER, JSON.stringify(user));
          this.authenticated = true;
          this.loadUserProfile();
        }
        this.setUser();

        localStorage.setItem('Access-Token-Azure-B2C', payload.idToken);

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          idtoken.acr === b2cPolicies.names.editProfile ||
          idtoken.tfp === b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = this.authService.instance
            .getAllAccounts()
            .find(
              (account: AccountInfo) =>
                account.idTokenClaims?.oid === idtoken.oid &&
                account.idTokenClaims?.sub === idtoken.sub &&
                ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr ===
                  b2cPolicies.names.signUpSignIn ||
                  (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp ===
                    b2cPolicies.names.signUpSignIn)
            );

          let signUpSignInFlowRequest: SsoSilentRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          this.authService.ssoSilent(signUpSignInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        if (
          idtoken.acr === b2cPolicies.names.resetPassword ||
          idtoken.tfp === b2cPolicies.names.resetPassword
        ) {
          let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
            scopes: [],
          };

          this.login(signUpSignInFlowRequest);
        }
      });
  }

  private subscribeEvents() {
    this.profileService.signUpEvent
      .pipe(takeUntil(this._destroying$))
      .subscribe((value) => {
        if (value) {
          this.authTrigger(null, value);
        }
      });
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest): void {
    if (userFlowRequest) {
      this.auth.login(userFlowRequest);
    } else {
      this.auth.login();
    }
  }

  private async loadUserProfile(): Promise<void> {
    let user = this.profileService.getUserClaims();
    if (user.role === '') {
      this.auth.getUerProfile(user.email).subscribe((response: any) => {
        const userData = response.data;
        user = {
          userId: userData.userProfile.id,
          firstName: userData.userProfile.firstName,
          lastName: userData.userProfile.lastName,
          role: userData.role,
          email: user.email,
        };
        // Store the basic user details in local storage
        localStorage.setItem(AppConstants.USER, JSON.stringify(user));

        //check user status to reactivate user Need to discuss
        //this.checkUserStatus(userData);
        this.gotoProfile(user);
      });
    } else {
      this.gotoProfile(user);
    }
  }

  checkUserStatus(user: any): void {
    if (!user.userProfile.isActive) {
      this.profileService.enableDisableUser(user.userProfile.id).subscribe({
        next: (resp) => {
          if (resp.data) {
            this.toastr.success(AppConstants.REACTIVATED, '', {
              timeOut: 60000,
              closeButton: true,
            });
          }
        },
        error: (err) => {
          console.error('Error enabling user', err);
        },
      });
    }
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  getCommonContent(): void {
    this.contentService.getContentItemByPath20('/').subscribe({
      next: (resp: any) => {
        this.commonContent = resp.properties;
      },
      complete: () => {},
      error: (error) => {
        console.log(error);
      },
    });
  }

  private isLogin(obj: any): boolean {
    return (
      obj.acr === environment.b2cConfigs.policyNames.signUpSignIn ||
      obj.tfp === environment.b2cConfigs.policyNames.signUpSignIn
    );
  }

  private getRole(obj: any): string {
    let role = '';
    if (obj.tfp == environment.b2cConfigs.policyNames.signupEmployer)
      role = SignUpRoles.Employer;
    else if (obj.tfp == environment.b2cConfigs.policyNames.signupJobseeker)
      role = SignUpRoles.JobSeeker;
    return role;
  }

  private authTrigger(userFlowRequest: any, type: any): void {
    if (type) {
      let authority: string | undefined;
      const b2cPolicies = {
        authorities: {
          signUpSignIn: {
            authority: `https://${b2cConfigs.tenantName}.b2clogin.com/${b2cConfigs.tenantName}.onmicrosoft.com/${b2cConfigs.policyNames.signUpSignIn}`,
          },
          editProfile: {
            authority: `https://${b2cConfigs.tenantName}.b2clogin.com/${b2cConfigs.tenantName}.onmicrosoft.com/${b2cConfigs.policyNames.editProfile}`,
          },
          resetPassword: {
            authority: `https://${b2cConfigs.tenantName}.b2clogin.com/${b2cConfigs.tenantName}.onmicrosoft.com/${b2cConfigs.policyNames.resetPassword}`,
          },
          signupEmployer: {
            authority: `https://${b2cConfigs.tenantName}.b2clogin.com/${b2cConfigs.tenantName}.onmicrosoft.com/${b2cConfigs.policyNames.signupEmployer}`,
          },
          signupJobseeker: {
            authority: `https://${b2cConfigs.tenantName}.b2clogin.com/${b2cConfigs.tenantName}.onmicrosoft.com/${b2cConfigs.policyNames.signupJobseeker}`,
          },
        },
        authorityDomain: `${b2cConfigs.tenantName}.b2clogin.com`,
      };
      switch (type) {
        case 'jobseeker':
          authority = b2cPolicies.authorities.signupJobseeker.authority;
          break;
        case 'employer':
          authority = b2cPolicies.authorities.signupEmployer.authority;
          break;
      }
      localStorage.removeItem('authtrigger');
      if (localStorage.getItem('Access-Token-Azure-B2C') == null) {
        this.authService.instance = new PublicClientApplication({
          auth: {
            clientId: b2cConfigs.clientId,
            authority: authority,
            knownAuthorities: [b2cPolicies.authorityDomain],
            redirectUri: b2cConfigs.redirectUri,
            postLogoutRedirectUri: b2cConfigs.logoutRedirectUri,
          },
        });

        this.authService.initialize().subscribe((ev: any) => {
          this.authService
            .handleRedirectObservable()
            .subscribe((response: any) => {
              if (this.msalGuardConfig.authRequest) {
                this.authService.loginRedirect({
                  ...this.msalGuardConfig.authRequest,
                  ...userFlowRequest,
                } as RedirectRequest);
              } else {
                this.authService.loginRedirect(userFlowRequest);
              }
            });
        });
      }
    }
  }

  private gotoProfile(obj: any): void {
    if (obj.role === SignUpRoles.Employer) {
      this.router.navigate([AppConstants.EMPLOYER_PROFILE_URL]);
    } else if (obj.role === SignUpRoles.JobSeeker) {
      this.router.navigate([AppConstants.JOBSEEKER_PROFILE_URL]);
    } else {
      this.router.navigate(['/']);
    }
  }

  private async setUser(): Promise<void> {
    let user = this.profileService.getUserClaims();
    if (user.userId || !user.role) return;
    await this.userInfoService
      .createProfile({
        email: user.email,
        role: user.role,
      })
      .subscribe((res: any) => {
        localStorage.setItem(
          AppConstants.USER,
          JSON.stringify({
            userId: res.data,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            email: user.email,
          })
        );
      });
  }
}
